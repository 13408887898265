<template>
  <div>
    <div>
      <system-bar></system-bar>
      <admin-menu></admin-menu>
    </div>
    <div>
      <v-card>
        <v-card-text>
          <signup-form
            @signupSuccess="signupSuccess"
            v-bind:id="memberId"
            v-bind:memberType="memberType"
            v-bind:userType="userType"
          >
          </signup-form>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import SystemBar from "../../../library/system-bar/system-bar";
import AdminMenu from "../../menubar/menubar";
import SignupForm from "./edit";
export default {
  components: {
    "signup-form": SignupForm,
    "admin-menu": AdminMenu,
    "system-bar": SystemBar,
  },
  name: "CreateLab",
  data() {
    return {
      memberType: "Admin",
      userType: "admin",
      url: process.env.VUE_APP_LOYALTY_API_URI,
      custId: process.env.VUE_APP_OGF_CUST_ID,
      memberId: "",
    };
  },
  mounted() {
    if (typeof this.$route.params.id !== "undefined") {
      this.memberId = this.$route.params.id;
    }
  },
  methods: {
    signupSuccess(response) {
      console.log(response);
      alert("Saved");
      this.$router.back();
    },
  },
};
</script>
