<template>
  <v-container>
    <v-row justify="space-around">
      <v-card max-width="960" ref="form">
        <v-card-title>
          Edit <v-spacer></v-spacer><v-btn @click="back">&lt; Back </v-btn></v-card-title
        >

        <v-card-text>
          <v-container>
            <v-row>
              <v-col md="8" offset-md="2">
                <v-text-field
                  v-model="regForm.firstname"
                  :rules="nameRules"
                  label="First Name"
                  required
                  id="firstname"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="8" offset-md="2">
                <v-text-field
                  v-model="regForm.lastname"
                  :rules="nameRules"
                  label="Last Name(Family Name/Surname)"
                  required
                  id="lastname"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="8" offset-md="2">
                <v-text-field
                  v-model="regForm.email"
                  :rules="emailRules"
                  label="Email"
                  type="email"
                  required
                  id="email"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="6">
                <v-text-field
                  v-model="regForm.mobile"
                  :rules="mobileRules"
                  label="Mobile"
                  required
                  id="mobile"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" sm="12">
                <v-text-field
                  v-model="regForm.password"
                  :counter="12"
                  label="Password"
                  required
                  type="password"
                  maxlength="18"
                  id="password"
                  ref="password"
                ></v-text-field>
              </v-col>
              <v-col md="6" sm="12">
                <v-text-field
                  v-model="regForm.confirmPassword"
                  label="Confirm Password"
                  :rules="regForm.confirmPassword != '' ? confirmPasswordRules : ''"
                  required
                  type="password"
                  maxlength="18"
                  id="confirmPassword"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-container>
            <v-row>
              <v-col>
                <v-btn :disabled="!valid" class="mr-4" @click="validate"> Save </v-btn>

                <v-btn class="mr-4" @click="reset"> Reset </v-btn>

                <!--v-btn color="warning" @click="resetValidation"> Reset Validation </!--v-btn-->
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {
    userType: {
      type: String,
    },
    memberType: {
      type: String,
    },
    id: {
      type: String,
    },
  },
  data: () => ({
    profile: {},
    editAddress: {},
    url: process.env.VUE_APP_LOYALTY_API_URI,
    custId: process.env.VUE_APP_OGF_CUST_ID,
    memberId: "",
    responseStatus: {},
    responseData: {},
    chkValid: false,
    valid: true,

    //url: process.env.VUE_APP_LOYALTY_API_URI,
    regForm: {
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      password: "",
      confirmPassword: "",
    },
    hasError: false,

    name: "",
    nameRules: [
      (v) => !!v || "This field is required",
      (v) => (v && v.length >= 5) || "Name must be more than 5 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /[^@]+@[^@]+.[a-zA-Z]{2,6}/.test(v) || "E-mail must be valid",
    ],
    mobile: "",
    mobileRules: [
      (v) => !!v || "Mobile is required",
      (v) => /(^0)(6|8|9)[0-9]{8,8}/.test(v) || "Mobile must be valid",
    ],
    password: "",
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) =>
        /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{7,23}$/.test(
          v
        ) ||
        "Password must have Numberic, Lowercase, Uppercase, special charactor ( !@#$%^&* ) and must between 6-18 charactors ",
    ],
    confirmPassword: "",
    confirmPasswordRules: [
      (v) => !!v || "Confirm password is required",
      (v) => {
        let pwd = document.getElementById("password");
        if (pwd != "undefined") {
          if (document.getElementById("password").value == v) {
            return true;
          } else {
            return "Confirm password must equal password ";
          }
        }
      },
    ],

    requiredRules: [(v) => !!v || "This field is required"],
    items: ["Dentist", "Orthodontist", "Other Specialist"],
  }),
  computed: {
    ...mapGetters({ getLabProfile: "BFC/getLabProfile" }),
  },
  mounted() {
    // if (this.$cookies.get("Auth").authType == "ADMIN") {
    //   this.regForm = this.getLabProfile;
    //   this.regForm.password = "";
    //   console.log("profile");
    //   console.log(this.regForm);
    // }
    this.init();
  },
  methods: {
    requiredFields() {
      return {
        custId: process.env.VUE_APP_OGF_CUST_ID,
        firstname: this.regForm.firstname,
        lastname: this.regForm.lastname,
        mobile: this.regForm.mobile,
        email: this.regForm.email,
        password: this.regForm.password,
        //member_type: this.memberType,
      };
    },
    async init() {
      console.log("mounted");
      let payload = {
        custId: this.custId,
        id: this.$route.params.id,
      };

      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/admin/info",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              this.regForm = response.data.data[0];
              this.regForm.password = "";
              console.log(this.regForm);
              //this.admin = this.getAdminList;
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
    },

    async validate() {
      this.hasError = false;
      let breakForEachException = {};
      //let uri = "";

      let uri = "/api/bfc/v1/admin/updateProfile";

      try {
        await Object.keys(this.requiredFields()).forEach((f) => {
          if (!this.regForm[f]) {
            console.log(f);
            if (f != "password" && f != "confirmPassword") {
              this.hasError = true;
              document.getElementById(f).focus();
              throw breakForEachException;
            }
          }
        });
        //console.log("validation");
        this.chkValid = true;

        if (!this.hasError) {
          //this.valid = true;
          // send
          let payload = {
            ...this.requiredFields(),
            id: this.$route.params.id,
            //mode: "edit",
          };
          await axios
            .post(
              this.url + uri,
              payload, // need to change
              {
                headers: {
                  // Overwrite Axios's automatically set Content-Type
                  "Content-Type": "application/json",
                },
              }
            )
            .then((response) => {
              // console.log(response);

              if (response.data.statusCode == 0) {
                this.responseStatus = {
                  status: "Success",
                  code: 0,
                  color: "green",
                  type: "success",
                  text: "สำเร็จ",
                  alertStatus: true,
                };

                //this.responseData = response.data.data;
                alert(response.data.statusMessage);
                this.$router.back();
              } else {
                alert(response.data.statusMessage);
              }
            });
        }
        //this.$emit("signupSuccess", this.responseData);
      } catch (e) {
        if (e != breakForEachException) throw e;
      }
    },
    reset() {
      this.regForm = {};
      this.address = {};
    },
    resetValidation() {},

    updateValid(val) {
      this.chkValid = val;
    },
    back() {
      this.$router.back();
    },
  },
  watch: {},
};
</script>
